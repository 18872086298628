import auth from '@/middleware/auth'


export default [
  {
    path: '/users',
    name: 'Users',
    component:() => import('@/views/users/index'),
    meta: {
      pageTitle: 'Users',
      middleware: [auth],
    }
  },
  {
    path: '/users/teachers',
    name: 'Teachers',
    component:() => import('@/views/users/Teachers'),
    meta: {
      pageTitle: 'Teachers',
      middleware: [auth],
    }
  },
]
