import {loginTheUser, refreshToken,logoutUser} from '../../api/auth';
import {setAccessToken,setRefreshToken,setUserData, removeAccessToken, removeRefreshToken,getRefreshToken} from '../../libs/auth'

export default {
    namespaced:true,
    state:{

    },
    getters:{

    },
    mutations:{
        SET_JWT_TOKEN(state,payload) {

        }
    },
    actions:{
        jwtLogin({commit},user) {
            return new Promise((resolve,reject) => {
                loginTheUser(user).then(res => {
                    setAccessToken(res.data.access_token);
                    setRefreshToken(res.data.access_token);
                    let userdata = res.data;
                    delete userdata.access_token;
                    setUserData(userdata)
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        fetchAccessToken() {
            let data = {refresh:getRefreshToken()};
            return new Promise((resolve, reject) => {
                refreshToken(data).then(res => {
                    setAccessToken(res.data.access_token);
                    // setRefreshToken(res.data.access_token);
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        logoutTheUser() {
            let data = {refresh:getRefreshToken()};
            return new Promise((resolve, reject) => {
                logoutUser(data).then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err);
                })
            })
        }
    }
}
