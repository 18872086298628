import auth from '@/middleware/auth'


export default [
  {
    path: '/experiences',
    name: 'Experiences',
    component:() => import('@/views/experience/index'),
    meta: {
      pageTitle: 'Experiences',
      middleware: [auth],
    }
  },
]
