import request from '../libs/axios'

export function loginTheUser(user) {
  return request({
    url: '/api/auth/login',
    method: 'post',
    data: user,
  })
}

export function refreshToken(data) {
  return request({
    url: '/api/auth/refresh/',
    method: 'post',
    data,
  })
}

export function logoutUser(data) {
  return request({
    url: '/api/auth/logout/',
    method: 'post',
    data,
  })
}

export function fetchUsersList(params) {
  return request({
    url: '/api/auth/users/',
    method: 'get',
    params,
  })
}

export function storeUser(data) {
  return request({
    url: '/api/auth/users/',
    method: 'post',
    data,
  })
}

export function updateUser(data) {
  const id = data instanceof FormData ? data.get('id') : data.id
  return request({
    url: `/api/auth/update-user/${id}/`,
    method: 'put',
    data,
  })
}

export function deleteUser(params) {
  return request({
    url: `/api/auth/users/${params.id}`,
    method: 'delete',
  })
}

export function userDetail(params) {
  return request(({
    url: `/api/auth/users/${params.id}`,
    method: 'get',
  }))
}

export function librariansList(params) {
  return request(({
    url: '/api/auth/librarians/',
    method: 'get',
    params,
  }))
}

export function deleteLibrarians(params) {
  return request({
    url: `/api/auth/librarian/${params.id}`,
    method: 'delete',
  })
}

export function updateLibrarians(data) {
  return request({
    url: `/api/auth/librarian/${data.get('id')}`,
    method: 'post',
    data,
  })
}

export function storeLibrarians(data) {
  return request({
    url: '/api/auth/librarians/',
    method: 'post',
    data,
  })
}

export function librarianDetail(params) {
  return request(({
    url: `/api/auth/librarian/${params.id}`,
    method: 'get',
  }))
}

export function getRoles() {
  return request(({
    url: '/api/auth/role-list',
    method: 'get',
  }))
}

export function getLanguages() {
  return request({
    url: '/api/auth/get-languages',
    method: 'get',
  });
}

export function changeUserLocale(data) {
  return request({
    url: '/api/auth/change-user-locale',
    method:'post',
    data,
  });
}
