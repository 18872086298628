import {getAccessToken} from '../libs/auth'
export default function guest ({next, store}) {
    if(getAccessToken()) {
        window.location = '/';
        // return next({
        //     name:'home'
        // })
    }
    return next()
}
