import auth from '@/middleware/auth'

export default [
  {
    path: '/regions',
    name: 'Regions',
    component:() => import('@/views/region/index'),
    meta: {
      pageTitle: 'Regions',
      middleware: [auth],
    }
  },
  {
    path: '/regions/:id',
    name: 'RegionsChildren',
    component:() => import('@/views/region/Children'),
    meta: {
      pageTitle: 'RegionsChildren',
      middleware: [auth],
    }
  },
]
