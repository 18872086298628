import auth from '@/middleware/auth'

export default [
  {
    path: '/nomination',
    name: 'Nomination',
    component:() => import('@/views/nominations/Nomination'),
    meta: {
      pageTitle: 'Nomination',
      middleware: [auth],
    }
  },
  {
    path: '/nomination/stage-questions/:stageId',
    name: 'NominationGeneralQuestions',
    component:() => import('@/views/nominations/GeneralQuestion'),
    meta: {
      pageTitle: 'NominationGeneralQuestions',
      middleware: [auth],
    }
  },
  {
    path: '/nomination/stage-questions/:stageId/:variantId',
    name: 'VariantNominationGeneralQuestions',
    component:() => import('@/views/nominations/GeneralQuestion'),
    meta: {
      pageTitle: 'NominationGeneralQuestions',
      middleware: [auth],
    }
  },
  {
    path: '/nomination/questions-stages',
    name: 'NominationQuestionStages',
    component:() => import('@/views/nominations/GeneralQuestionStages'),
    meta: {
      pageTitle: 'NominationQuestionStage',
      middleware: [auth],
    }
  },
  {
    path: '/nomination/questions-stages/:variantId',
    name: 'NominationQuestionStagesVariant',
    component:() => import('@/views/nominations/GeneralQuestionStages'),
    meta: {
      pageTitle: 'NominationQuestionStage',
      middleware: [auth],
    }
  },
  {
    path: '/nomination/questions/:id',
    name: 'NominationQuestions',
    component:() => import('@/views/nominations/NominationQuestion'),
    meta: {
      pageTitle: 'NominationQuestions',
      middleware: [auth],
    }
  },
  {
    path: '/nomination/contents/:id',
    name: 'NominationContents',
    component:() => import('@/views/nominations/NominationContent'),
    meta: {
      pageTitle: 'NominationContents',
      middleware: [auth],
    }
  }
]
