import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '../middleware/auth'
import guest from '../middleware/guest'
import nomination from '@/router/nomination'
import region from '@/router/region'
import experience from '@/router/experience'
import application from '@/router/application'
import users from '@/router/users'
import middlewarePipeline from '../middleware/middlewarePipeline'
import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        middleware: [auth],
      },
    },
    ...nomination,
    ...region,
    ...experience,
    ...users,
    ...application,
    {
      path: '/question-variants',
      name: 'QuestionVariantStages',
      component: () => import('@/views/question-variant/variant-stages'),
      meta: {
        pageTitle: '',
        middleware: [auth],
      },
    },
    {
      path: '/question-variants/:stageId',
      name: 'QuestionVariants',
      component: () => import('@/views/question-variant/index'),
      meta: {
        pageTitle: '',
        middleware: [auth],
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
        middleware: [auth],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        middleware: [guest],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        middleware: [guest],
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    next()
  }
  const { middleware } = to.meta
  const context = {
    to,
    from,
    next,
    store,
  }
  if (middleware) {
    return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1),
    })
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
