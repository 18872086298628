
const ACCESS_TOKEN = 'UZEDU_ACCESS_TOKEN';
const REFRESH_TOKEN = 'UZEDU_REFRESH_TOKEN';
const AUTH_USER_DATA = 'AUTH_USER_DATA';
const USER_LOCALE = 'USER_LOCALE';

export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN);

export const setAccessToken = (token) => {
  localStorage.setItem(ACCESS_TOKEN, token);
}

export const removeAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN);
}

export const setRefreshToken = (token) => {
  localStorage.setItem(REFRESH_TOKEN, token);
}

export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN);

export const removeRefreshToken = () => {
  localStorage.removeItem(REFRESH_TOKEN);
}
export const setUserData = data => {
  localStorage.setItem(AUTH_USER_DATA,JSON.stringify(data))
}
export const getUserData = () => {
  return JSON.parse(localStorage.getItem(AUTH_USER_DATA));
}
export const removeUserData = () => {
  localStorage.removeItem(AUTH_USER_DATA);
}
export const saveUserLang = (lang) => {
  localStorage.setItem(USER_LOCALE, lang);
}
export const getUserlang = () =>  localStorage.getItem(USER_LOCALE);


