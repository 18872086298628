import auth from '@/middleware/auth'


export default [
  {
    path: '/applications',
    name: 'RequestesApplications',
    component: () => import('@/views/applications/index'),
    meta: {
      pageTitle: 'Applications',
      middleware: [auth],
    }
  },
  {
    path: '/new-applications',
    name: 'NewApplications',
    component: () => import('@/views/applications/NewApplications'),
    meta: {
      pageTitle: 'Applications',
      middleware: [auth],
    }
  },
  {
    path: '/onprogress-applications',
    name: 'OnProgressApplications',
    component: () => import('@/views/applications/OnProgress'),
    meta: {
      pageTitle: 'Applications',
      middleware: [auth],
    }
  },
  {
    path: '/rejected-applications',
    name: 'RejectedApplications',
    component: () => import('@/views/applications/Rejected'),
    meta: {
      pageTitle: 'Applications',
      middleware: [auth],
    }
  },
  {
    path: '/accepted-applications',
    name: 'AcceptedApplications',
    component: () => import('@/views/applications/AcceptedStages'),
    meta: {
      pageTitle: 'Applications',
      middleware: [auth],
    },
  },
  {
    path: '/accepted-application/:stageId',
    name: 'AcceptedApplicationStages',
    component: () => import('@/views/applications/Accepted'),
    meta: {
      pageTitle: 'Applications',
      middleware: [auth],
    },
  },

  {
    path: '/applications/jury/:stageId',
    name: 'ApplicationsJuryByStage',
    component: () => import('@/views/jury/index'),
    meta: {
      pageTitle: 'Applications',
      middleware: [auth],
    }
  },
  {
    path: '/new-applications/jury',
    name: 'ApplicationsJury',
    component: () => import('@/views/jury/markedStages'),
    meta: {
      pageTitle: 'Applications',
      middleware: [auth],
    }
  },
  {
    path: '/new-applications/jury',
    name: 'NewApplicationsJury',
    component: () => import('@/views/jury/NewApplcationStages'),
    meta: {
      pageTitle: 'NewApplicationsJury',
      middleware: [auth],
    }
  },
  {
    path: '/new-applications/jury/:stageId',
    name: 'NewApplicationsJuryByStage',
    component: () => import('@/views/jury/NewApplications'),
    meta: {
      pageTitle: 'NewApplicationsJury',
      middleware: [auth],
    }
  },
  {
    path: '/applications/marks',
    name: 'ApplicationsMarks',
    component: () => import('@/views/applications/MarksStages'),
    meta: {
      pageTitle: 'ApplicationsMarks',
      middleware: [auth],
    }
  },
  {
    path: '/applications/marks/:stageId',
    name: 'ApplicationsMarksByStage',
    component: () => import('@/views/applications/Marks'),
    meta: {
      pageTitle: 'ApplicationsMarks',
      middleware: [auth],
    }
  },
]
