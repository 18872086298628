
// axios
import axios from 'axios'

import { getAccessToken, getUserlang , removeAccessToken, removeRefreshToken, removeUserData } from './auth'
import store from '@/store';
import AppConfig from './vue-axios.config';


let isAlreadyFetchingAccessToken = false
let subscribers = []
function onAccessTokenFetched (access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber (callback) {
  subscribers.push(callback)
}


const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: AppConfig.backendPath,
  timeout: 150000,
  // headers: {'X-Custom-Header': 'foobar'}
});

axiosIns.interceptors.request.use(config => {
  config.headers['Content-Type'] = 'application/json';

  config.headers['Accept'] = 'application/json';
  config.headers['locale'] = getUserlang() ? getUserlang() : 'ru';
  if(getAccessToken()) {
    config.headers['Authorization'] = `Bearer ${getAccessToken()}`
  }

  return config;
},
error => {
  Promise.reject(error);
});

axiosIns.interceptors.response.use(
  response => {

    if(response.message === "Unauthorized") {
        window.location = '/login';
    }

    return response;
  },
  error => {
    const originalRequest = error.config;
    if(error.response.message === "Unauthorized" && !getAccessToken()) {

      window.location = '/login';
    }
    else if(error.response.status === 401 && getAccessToken()) {

      if(!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        store.dispatch('auth/fetchAccessToken').then(res => {

          isAlreadyFetchingAccessToken = false;
          onAccessTokenFetched(res.data.access_token);
        }).catch(err => {
          removeAccessToken();
          window.location = '/login';
        });
      }
      const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber(access_token => {
          originalRequest.headers.Authorization = `Bearer ${access_token}`
          resolve(axios(originalRequest))
        })
      })

      return retryOriginalRequest

    }
    // else if(error.response.status === 405) {
    //       window.location = "/page-405";
    //   }
      return Promise.reject(error)

  }
)

// Vue.prototype.$http = axiosIns

export default axiosIns
